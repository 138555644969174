import React from "react"
import styles from "./contact.module.scss"
import MdArrowBack from "react-ionicons/lib/MdArrowBack"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export default class Contact extends React.PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <SEO title="Contact Deep Patel" />
        <div className={styles.wrapper}>
          <Link to="/">
            <div className={styles.header}>
              <MdArrowBack />
            </div>
          </Link>
          <Container>
            <Row>
              <Col>
                <div className={styles.form}>
                  <form
                    action="https://formspree.io/deep@deeppatel.com"
                    method="POST"
                  >
                    <div className={styles.inputContainer}>
                      <div className={styles.label}>My name is...</div>
                      <input type="text" name="name" />
                    </div>
                    <div className={styles.inputContainer}>
                      <div className={styles.label}>My email is...</div>
                      <input
                        type="email"
                        name="_replyto"
                        placeholder="dwight@schrute.com"
                      />
                    </div>
                    <div className={styles.inputContainer}>
                      <div className={styles.label}>
                        &amp; I need help with...
                      </div>
                      <textarea name="_message" placeholder="your message" />
                    </div>
                    <div className={styles.inputContainer}>
                      <input type="submit" value="Send" />
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}
